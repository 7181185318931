import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`This Saturday, July 18th, is our Ville in-house competition!  Sign up
with the link provided in our latest email or just email us and we’ll
sign you up.  RX and Scaled divisions so this competition is for all of
our members.  Arrive by 8:30am, first wod to start at 9:00.  `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      